import { graphql } from "gatsby";
import { CoreParagraphBlockFragment } from "../components/blocks/CoreParagraphBlock/"; // eslint-disable-line
import { CoreHeadingBlockFragment } from "../components/blocks/CoreHeadingBlock"; // eslint-disable-line
import { CoreImageBlockFragment } from "../components/blocks/CoreImageBlock"; // eslint-disable-line
import { CoreColumnBlockFragment } from "../components/blocks/CoreColumnBlock"; // eslint-disable-line
import { CoreColumnsBlockFragment } from "../components/blocks/CoreColumnsBlock"; // eslint-disable-line
import { CoreButtonsBlockFragment } from "../components/blocks/CoreButtonsBlock"; // eslint-disable-line
import { CoreButtonBlockFragment } from "../components/blocks/CoreButtonBlock"; // eslint-disable-line
import { CoreGalleryBlockFragment } from "../components/blocks/CoreGalleryBlock"; // eslint-disable-line
import { CoreQuoteBlockFragment } from "../components/blocks/CoreQuoteBlock"; // eslint-disable-line
import { CoreListBlockFragment } from "../components/blocks/CoreListBlock"; // eslint-disable-line
import { CoreListItemBlockFragment } from "../components/blocks/CoreListItemBlock"; // eslint-disable-line
import { CoreTableBlockFragment } from "../components/blocks/CoreTableBlock"; // eslint-disable-line
import { CoreFileBlockFragment } from "../components/blocks/CoreFileBlock"; // eslint-disable-line
import { CoreSpacerBlockFragment } from "../components/blocks/CoreSpacerBlock"; // eslint-disable-line
import { GravityformsFormBlockFragment } from "../components/blocks/GravityformsFormBlock"; // eslint-disable-line

import { CgbAvatarBreakdownBlockFragment } from "../components/blocks/CgbAvatarBreakdownBlock"; // eslint-disable-line
import { CgbBannerCalloutCtaBlockFragment } from "../components/blocks/CgbBannerCalloutCtaBlock"; // eslint-disable-line
import { CgbBannerMessageBlockFragment } from "../components/blocks/CgbBannerMessageBlock"; // eslint-disable-line
import { CgbBeerListingBlockFragment } from "../components/blocks/CgbBeerListingBlock"; // eslint-disable-line
import { CgbBlogPostBannerBlockFragment } from "../components/blocks/CgbBlogPostBannerBlock"; // eslint-disable-line
import { CgbBlogPostWrapBlockFragment } from "../components/blocks/CgbBlogPostWrapBlock"; // eslint-disable-line
import { CgbBreakoutGalleryIntroBlockFragment } from "../components/blocks/CgbBreakoutGalleryIntroBlock"; // eslint-disable-line
import { CgbCalloutCtaBlockFragment } from "../components/blocks/CgbCalloutCtaBlock"; // eslint-disable-line
import { CgbContactFormBrandBlockFragment } from "../components/blocks/CgbContactFormBrandBlock"; // eslint-disable-line
import { CgbCardBlockFragment } from "../components/blocks/CgbCardBlock"; // eslint-disable-line
import { CgbCardRowBlockFragment } from "../components/blocks/CgbCardRowBlock"; // eslint-disable-line
import { CgbContactCalloutBlockFragment } from "../components/blocks/CgbContactCalloutBlock"; // eslint-disable-line
import { CgbFeaturedIconsBlockFragment } from "../components/blocks/CgbFeaturedIconsBlock"; // eslint-disable-line
import { CgbFeaturedIconsInnerBlockFragment } from "../components/blocks/CgbFeaturedIconsInnerBlock"; // eslint-disable-line
import { CgbFeaturedProductsBlockFragment } from "../components/blocks/CgbFeaturedProductsBlock"; // eslint-disable-line
import { CgbFeaturedTextBlockFragment } from "../components/blocks/CgbFeaturedTextBlock"; // eslint-disable-line
import { CgbFindPubBlockFragment } from "../components/blocks/CgbFindPubBlock"; // eslint-disable-line
import { CgbFormCareersBlockFragment } from "../components/blocks/CgbFormCareersBlock"; // eslint-disable-line
import { CgbFormSignUpBlockFragment } from "../components/blocks/CgbFormSignUpBlock"; // eslint-disable-line
import { CgbFormVacancySignUpBlockFragment } from "../components/blocks/CgbFormVacancySignUpBlock"; // eslint-disable-line
import { CgbGoodToGoBlockFragment } from "../components/blocks/CgbGoodToGoBlock"; // eslint-disable-line
import { CgbImageBreakdownBlockFragment } from "../components/blocks/CgbImageBreakdownBlock"; // eslint-disable-line
import { CgbImageBreakdownInnerBlockFragment } from "../components/blocks/CgbImageBreakdownInnerBlock"; // eslint-disable-line
import { CgbImageContentRowsBlockFragment } from "../components/blocks/CgbImageContentRowsBlock"; // eslint-disable-line
import { CgbImageContentRowsInnerBlockFragment } from "../components/blocks/CgbImageContentRowsInnerBlock"; // eslint-disable-line
import { CgbImageLinkSliderBlockFragment } from "../components/blocks/CgbImageLinkSliderBlock"; // eslint-disable-line
import { CgbImageLinkSliderInnerBlockFragment } from "../components/blocks/CgbImageLinkSliderInnerBlock"; // eslint-disable-line
import { CgbLatestPostsBlockFragment } from "../components/blocks/CgbLatestPostsBlock"; // eslint-disable-line
import { CgbPubListingBlockFragment } from "../components/blocks/CgbPubListingBlock"; // eslint-disable-line
import { CgbSeparatorBlockFragment } from "../components/blocks/CgbSeparatorBlock"; // eslint-disable-line
import { CgbSocialSharingBlockFragment } from "../components/blocks/CgbSocialSharingBlock"; // eslint-disable-line
import { CgbStatColumnsBlockFragment } from "../components/blocks/CgbStatColumnsBlock"; // eslint-disable-line
import { CgbTabContentBlockFragment } from "../components/blocks/CgbTabContentBlock"; // eslint-disable-line
import { CgbTabContentInnerBlockFragment } from "../components/blocks/CgbTabContentInnerBlock"; // eslint-disable-line
import { CgbTestimonialBlockFragment } from "../components/blocks/CgbTestimonialBlock"; // eslint-disable-line
import { CgbTestimonialSliderBlockFragment } from "../components/blocks/CgbTestimonialSliderBlock"; // eslint-disable-line
import { CgbTimelineBlockFragment } from "../components/blocks/CgbTimelineBlock"; // eslint-disable-line
import { CgbTimelineInnerBlockFragment } from "../components/blocks/CgbTimelineInnerBlock"; // eslint-disable-line
import { CgbThickIntroBlockFragment } from "../components/blocks/CgbThickIntroBlock"; // eslint-disable-line
import { CgbTwoColInnerBlockFragment } from "../components/blocks/CgbTwoColInnerBlock"; // eslint-disable-line
import { CgbTwoColWrapperBlockFragment } from "../components/blocks/CgbTwoColWrapperBlock"; // eslint-disable-line
import { CgbVacancySliderBlockFragment } from "../components/blocks/CgbVacancySliderBlock"; // eslint-disable-line
import { CgbVideoBannerMessageBlockFragment } from "../components/blocks/CgbVideoBannerMessageBlock"; // eslint-disable-line
import { CgbVideoEmbedBlockFragment } from "../components/blocks/CgbVideoEmbedBlock"; // eslint-disable-line
import { CgbVideoTestimonialBlockFragment } from "../components/blocks/CgbVideoTestimonialBlock"; // eslint-disable-line

import { CoreParagraphBlockPreview } from "../components/blocks/CoreParagraphBlock/"; // eslint-disable-line
import { CoreHeadingBlockPreview } from "../components/blocks/CoreHeadingBlock/"; // eslint-disable-line
import { CoreColumnBlockPreview } from "../components/blocks/CoreColumnBlock/"; // eslint-disable-line
import { CoreColumnsBlockPreview } from "../components/blocks/CoreColumnsBlock/"; // eslint-disable-line
import { CoreImageBlockPreview } from "../components/blocks/CoreImageBlock/"; // eslint-disable-line
import { CoreButtonsBlockPreview } from "../components/blocks/CoreButtonsBlock/"; // eslint-disable-line
import { CoreButtonBlockPreview } from "../components/blocks/CoreButtonBlock/"; // eslint-disable-line
import { CoreGalleryBlockPreview } from "../components/blocks/CoreGalleryBlock/"; // eslint-disable-line
import { CoreQuoteBlockPreview } from "../components/blocks/CoreQuoteBlock/"; // eslint-disable-line
import { CoreListBlockPreview } from "../components/blocks/CoreListBlock/"; // eslint-disable-lined
import { CoreListItemBlockPreview } from "../components/blocks/CoreListItemBlock/"; // eslint-disable-lined
import { CoreTableBlockPreview } from "../components/blocks/CoreTableBlock/"; // eslint-disable-lined
import { CoreFileBlockPreview } from "../components/blocks/CoreFileBlock/"; // eslint-disable-lined
import { CoreSpacerBlockPreview } from "../components/blocks/CoreSpacerBlock/"; // eslint-disable-lined
import { GravityformsFormBlockPreview } from "../components/blocks/GravityformsFormBlock"; // eslint-disable-line

import { CgbAvatarBreakdownBlockPreview } from "../components/blocks/CgbAvatarBreakdownBlock"; // eslint-disable-line
import { CgbBannerCalloutCtaBlockPreview } from "../components/blocks/CgbBannerCalloutCtaBlock"; // eslint-disable-line
import { CgbBannerMessageBlockPreview } from "../components/blocks/CgbBannerMessageBlock"; // eslint-disable-line
import { CgbBeerListingBlockPreview } from "../components/blocks/CgbBeerListingBlock"; // eslint-disable-line
import { CgbBlogPostBannerBlockPreview } from "../components/blocks/CgbBlogPostBannerBlock"; // eslint-disable-line
import { CgbBlogPostWrapBlockPreview } from "../components/blocks/CgbBlogPostWrapBlock"; // eslint-disable-line
import { CgbBreakoutGalleryIntroBlockPreview } from "../components/blocks/CgbBreakoutGalleryIntroBlock"; // eslint-disable-line
import { CgbCalloutCtaBlockPreview } from "../components/blocks/CgbCalloutCtaBlock"; // eslint-disable-line
import { CgbCardBlockPreview } from "../components/blocks/CgbCardBlock"; // eslint-disable-line
import { CgbCardRowBlockPreview } from "../components/blocks/CgbCardRowBlock"; // eslint-disable-line
import { CgbContactCalloutBlockPreview } from "../components/blocks/CgbContactCalloutBlock"; // eslint-disable-line
import { CgbContactFormBrandBlockPreview } from "../components/blocks/CgbContactFormBrandBlock"; // eslint-disable-line
import { CgbFeaturedIconsBlockPreview } from "../components/blocks/CgbFeaturedIconsBlock"; // eslint-disable-line
import { CgbFeaturedIconsInnerBlockPreview } from "../components/blocks/CgbFeaturedIconsInnerBlock"; // eslint-disable-line
import { CgbFeaturedProductsBlockPreview } from "../components/blocks/CgbFeaturedProductsBlock"; // eslint-disable-line
import { CgbFeaturedTextBlockPreview } from "../components/blocks/CgbFeaturedTextBlock"; // eslint-disable-line
import { CgbFindPubBlockPreview } from "../components/blocks/CgbFindPubBlock"; // eslint-disable-line
import { CgbFormCareersBlockPreview } from "../components/blocks/CgbFormCareersBlock"; // eslint-disable-line
import { CgbFormSignUpBlockPreview } from "../components/blocks/CgbFormSignUpBlock"; // eslint-disable-line
import { CgbFormVacancySignUpBlockPreview } from "../components/blocks/CgbFormVacancySignUpBlock"; // eslint-disable-line
import { CgbGoodToGoBlockPreview } from "../components/blocks/CgbGoodToGoBlock"; // eslint-disable-line
import { CgbImageBreakdownBlockPreview } from "../components/blocks/CgbImageBreakdownBlock"; // eslint-disable-line
import { CgbImageBreakdownInnerBlockPreview } from "../components/blocks/CgbImageBreakdownInnerBlock"; // eslint-disable-line
import { CgbImageContentRowsBlockPreview } from "../components/blocks/CgbImageContentRowsBlock"; // eslint-disable-line
import { CgbImageContentRowsInnerBlockPreview } from "../components/blocks/CgbImageContentRowsInnerBlock"; // eslint-disable-line
import { CgbImageLinkSliderBlockPreview } from "../components/blocks/CgbImageLinkSliderBlock"; // eslint-disable-line
import { CgbImageLinkSliderInnerBlockPreview } from "../components/blocks/CgbImageLinkSliderInnerBlock"; // eslint-disable-line
import { CgbLatestPostsBlockPreview } from "../components/blocks/CgbLatestPostsBlock"; // eslint-disable-line
import { CgbPubListingBlockPreview } from "../components/blocks/CgbPubListingBlock"; // eslint-disable-line
import { CgbSeparatorBlockPreview } from "../components/blocks/CgbSeparatorBlock"; // eslint-disable-line
import { CgbSocialSharingBlockPreview } from "../components/blocks/CgbSocialSharingBlock"; // eslint-disable-line
import { CgbStatColumnsBlockPreview } from "../components/blocks/CgbStatColumnsBlock"; // eslint-disable-line
import { CgbTabContentBlockPreview } from "../components/blocks/CgbTabContentBlock"; // eslint-disable-line
import { CgbTabContentInnerBlockPreview } from "../components/blocks/CgbTabContentInnerBlock"; // eslint-disable-line
import { CgbTestimonialBlockPreview } from "../components/blocks/CgbTestimonialBlock"; // eslint-disable-line
import { CgbTestimonialSliderBlockPreview } from "../components/blocks/CgbTestimonialSliderBlock"; // eslint-disable-line
import { CgbTimelineBlockPreview } from "../components/blocks/CgbTimelineBlock"; // eslint-disable-line
import { CgbTimelineInnerBlockPreview } from "../components/blocks/CgbTimelineInnerBlock"; // eslint-disable-line
import { CgbThickIntroBlockPreview } from "../components/blocks/CgbThickIntroBlock"; // eslint-disable-line
import { CgbTwoColInnerBlockPreview } from "../components/blocks/CgbTwoColInnerBlock"; // eslint-disable-line
import { CgbTwoColWrapperBlockPreview } from "../components/blocks/CgbTwoColWrapperBlock"; // eslint-disable-line
import { CgbVacancySliderBlockPreview } from "../components/blocks/CgbVacancySliderBlock"; // eslint-disable-line
import { CgbVideoBannerMessageBlockPreview } from "../components/blocks/CgbVideoBannerMessageBlock"; // eslint-disable-line
import { CgbVideoEmbedBlockPreview } from "../components/blocks/CgbVideoEmbedBlock"; // eslint-disable-line
import { CgbVideoTestimonialBlockPreview } from "../components/blocks/CgbVideoTestimonialBlock"; // eslint-disable-line

import { AcfAirshipEmbedBlockFragment } from "../components/blocks/AcfAirshipEmbedBlock"; // eslint-disable-line
import { AcfAirshipEmbedBlockPreview } from "../components/blocks/AcfAirshipEmbedBlock"; // eslint-disable-line
import { AcfBrewGalleryBlockFragment } from "../components/blocks/AcfBrewGalleryBlock"; // eslint-disable-line
import { AcfBrewGalleryBlockPreview } from "../components/blocks/AcfBrewGalleryBlock"; // eslint-disable-line
import { AcfFaqsBlockFragment } from "../components/blocks/AcfFaqsBlock"; // eslint-disable-line
import { AcfFaqsBlockPreview } from "../components/blocks/AcfFaqsBlock"; // eslint-disable-line
import { AcfFullWidthImageBlockFragment } from "../components/blocks/AcfFullWidthImageBlock"; // eslint-disable-line
import { AcfFullWidthImageBlockPreview } from "../components/blocks/AcfFullWidthImageBlock"; // eslint-disable-line
import { AcfPubListingBlockFragment } from "../components/blocks/AcfPubListingBlock"; // eslint-disable-line
import { AcfPubListingBlockPreview } from "../components/blocks/AcfPubListingBlock"; // eslint-disable-line
import { AcfTimedContainerBlockFragment } from "../components/blocks/AcfTimedContainerBlock"; // eslint-disable-line
import { AcfTimedContainerBlockPreview } from "../components/blocks/AcfTimedContainerBlock"; // eslint-disable-line

export const CoreFields = graphql`
  fragment CoreFields on WpBlock {
    name
    ...AcfPubListingBlock
    ...CoreParagraphBlock
    ...CoreHeadingBlock
    ...CoreImageBlock
    ...CoreColumnBlock
    ...CoreColumnsBlock
    ...CoreButtonsBlock
    ...CoreButtonBlock
    ...CoreGalleryBlock
    ...CoreQuoteBlock
    ...CoreListBlock
    ...CoreListItemBlock
    ...CoreTableBlock
    ...CoreFileBlock
    ...CoreSpacerBlock
    ...GravityformsFormBlock
    ...CgbAvatarBreakdownBlock
    ...CgbBannerCalloutCtaBlock
    ...CgbBannerMessageBlock
    ...CgbBeerListingBlock
    ...CgbBlogPostBannerBlock
    ...CgbBlogPostWrapBlock
    ...CgbBreakoutGalleryIntroBlock
    ...CgbCalloutCtaBlock
    ...CgbCardBlock
    ...CgbCardRowBlock
    ...CgbContactCalloutBlock
    ...CgbContactFormBrandBlock
    ...CgbFeaturedIconsBlock
    ...CgbFeaturedIconsInnerBlock
    ...CgbFeaturedProductsBlock
    ...CgbFeaturedTextBlock
    ...CgbFindPubBlock
    ...CgbFormCareersBlock
    ...CgbFormSignUpBlock
    ...CgbFormVacancySignUpBlock
    ...CgbGoodToGoBlock
    ...CgbImageBreakdownBlock
    ...CgbImageBreakdownInnerBlock
    ...CgbImageContentRowsBlock
    ...CgbImageContentRowsInnerBlock
    ...CgbImageLinkSliderBlock
    ...CgbImageLinkSliderInnerBlock
    ...CgbLatestPostsBlock
    ...CgbPubListingBlock
    ...CgbSeparatorBlock
    ...CgbSocialSharingBlock
    ...CgbStatColumnsBlock
    ...CgbTabContentBlock
    ...CgbTabContentInnerBlock
    ...CgbTestimonialBlock
    ...CgbTestimonialSliderBlock
    ...CgbTimelineBlock
    ...CgbTimelineInnerBlock
    ...CgbThickIntroBlock
    ...CgbTwoColInnerBlock
    ...CgbTwoColWrapperBlock
    ...CgbVacancySliderBlock
    ...CgbVideoBannerMessageBlock
    ...CgbVideoEmbedBlock
    ...CgbVideoTestimonialBlock
    ...AcfAirshipEmbedBlock
    ...AcfTimedContainerBlock
    ...AcfBrewGalleryBlock
    ...AcfFaqsBlock
    ...AcfFullWidthImageBlock
    innerBlocks {
      name
      ...CoreParagraphBlock
      ...CoreHeadingBlock
      ...CoreImageBlock
      ...CoreColumnBlock
      ...CoreColumnsBlock
      ...CoreButtonsBlock
      ...CoreButtonBlock
      ...CoreGalleryBlock
      ...CoreQuoteBlock
      ...CoreListBlock
      ...CoreListItemBlock
      ...CoreTableBlock
      ...CoreFileBlock
      ...CoreSpacerBlock
      ...GravityformsFormBlock
      ...CgbAvatarBreakdownBlock
      ...CgbBannerCalloutCtaBlock
      ...CgbBannerMessageBlock
      ...CgbBeerListingBlock
      ...CgbBlogPostBannerBlock
      ...CgbBlogPostWrapBlock
      ...CgbBreakoutGalleryIntroBlock
      ...CgbCalloutCtaBlock
      ...CgbCardBlock
      ...CgbCardRowBlock
      ...CgbContactCalloutBlock
      ...CgbContactFormBrandBlock
      ...CgbFeaturedIconsBlock
      ...CgbFeaturedIconsInnerBlock
      ...CgbFeaturedProductsBlock
      ...CgbFeaturedTextBlock
      ...CgbFormCareersBlock
      ...CgbGoodToGoBlock
      ...CgbImageBreakdownBlock
      ...CgbImageBreakdownInnerBlock
      ...CgbImageContentRowsBlock
      ...CgbImageContentRowsInnerBlock
      ...CgbImageLinkSliderBlock
      ...CgbImageLinkSliderInnerBlock
      ...CgbLatestPostsBlock
      ...CgbPubListingBlock
      ...CgbSeparatorBlock
      ...CgbSocialSharingBlock
      ...CgbStatColumnsBlock
      ...CgbTabContentBlock
      ...CgbTabContentInnerBlock
      ...CgbTestimonialBlock
      ...CgbTestimonialSliderBlock
      ...CgbTimelineBlock
      ...CgbTimelineInnerBlock
      ...CgbThickIntroBlock
      ...CgbTwoColInnerBlock
      ...CgbTwoColWrapperBlock
      ...CgbVacancySliderBlock
      ...CgbVideoBannerMessageBlock
      ...CgbVideoEmbedBlock
      ...CgbVideoTestimonialBlock
      ...AcfAirshipEmbedBlock
      ...AcfTimedContainerBlock
      ...AcfFaqsBlock
      ...AcfBrewGalleryBlock
      ...AcfFullWidthImageBlock
      innerBlocks {
        name
        ...CoreParagraphBlock
        ...CoreHeadingBlock
        ...CoreImageBlock
        ...CoreColumnBlock
        ...CoreColumnsBlock
        ...CoreButtonsBlock
        ...CoreButtonBlock
        ...CoreGalleryBlock
        ...CoreQuoteBlock
        ...CoreListBlock
        ...CoreListItemBlock
        ...CoreTableBlock
        ...CoreFileBlock
        ...CoreSpacerBlock
        ...GravityformsFormBlock
        ...CgbAvatarBreakdownBlock
        ...CgbBannerCalloutCtaBlock
        ...CgbBannerMessageBlock
        ...CgbBeerListingBlock
        ...CgbBlogPostBannerBlock
        ...CgbBlogPostWrapBlock
        ...CgbBreakoutGalleryIntroBlock
        ...CgbCalloutCtaBlock
        ...CgbCardBlock
        ...CgbCardRowBlock
        ...CgbContactCalloutBlock
        ...CgbContactFormBrandBlock
        ...CgbFeaturedIconsBlock
        ...CgbFeaturedIconsInnerBlock
        ...CgbFeaturedProductsBlock
        ...CgbFeaturedTextBlock
        ...CgbFormCareersBlock
        ...CgbGoodToGoBlock
        ...CgbImageBreakdownBlock
        ...CgbImageBreakdownInnerBlock
        ...CgbImageContentRowsBlock
        ...CgbImageContentRowsInnerBlock
        ...CgbImageLinkSliderBlock
        ...CgbImageLinkSliderInnerBlock
        ...CgbLatestPostsBlock
        ...CgbPubListingBlock
        ...CgbSeparatorBlock
        ...CgbSocialSharingBlock
        ...CgbStatColumnsBlock
        ...CgbTabContentBlock
        ...CgbTabContentInnerBlock
        ...CgbTestimonialBlock
        ...CgbTestimonialSliderBlock
        ...CgbTimelineBlock
        ...CgbTimelineInnerBlock
        ...CgbThickIntroBlock
        ...CgbTwoColInnerBlock
        ...CgbTwoColWrapperBlock
        ...CgbVacancySliderBlock
        ...CgbVideoBannerMessageBlock
        ...CgbVideoEmbedBlock
        ...CgbVideoTestimonialBlock
        ...AcfAirshipEmbedBlock
        ...AcfTimedContainerBlock
        ...AcfFaqsBlock
        ...AcfBrewGalleryBlock
        ...AcfFullWidthImageBlock
        innerBlocks {
          name
          ...CoreParagraphBlock
          ...CoreHeadingBlock
          ...CoreImageBlock
          ...CoreColumnBlock
          ...CoreColumnsBlock
          ...CoreButtonsBlock
          ...CoreButtonBlock
          ...CoreGalleryBlock
          ...CoreQuoteBlock
          ...CoreListBlock
          ...CoreListItemBlock
          ...CoreTableBlock
          ...CoreFileBlock
          ...CoreSpacerBlock
          ...GravityformsFormBlock
          ...CgbAvatarBreakdownBlock
          ...CgbBannerCalloutCtaBlock
          ...CgbBannerMessageBlock
          ...CgbBeerListingBlock
          ...CgbBlogPostBannerBlock
          ...CgbBlogPostWrapBlock
          ...CgbBreakoutGalleryIntroBlock
          ...CgbCalloutCtaBlock
          ...CgbCardBlock
          ...CgbCardRowBlock
          ...CgbContactCalloutBlock
          ...CgbContactFormBrandBlock
          ...CgbFeaturedIconsBlock
          ...CgbFeaturedIconsInnerBlock
          ...CgbFeaturedProductsBlock
          ...CgbFeaturedTextBlock
          ...CgbFormCareersBlock
          ...CgbGoodToGoBlock
          ...CgbImageBreakdownBlock
          ...CgbImageBreakdownInnerBlock
          ...CgbImageContentRowsBlock
          ...CgbImageContentRowsInnerBlock
          ...CgbImageLinkSliderBlock
          ...CgbImageLinkSliderInnerBlock
          ...CgbLatestPostsBlock
          ...CgbPubListingBlock
          ...CgbSeparatorBlock
          ...CgbSocialSharingBlock
          ...CgbStatColumnsBlock
          ...CgbTabContentBlock
          ...CgbTabContentInnerBlock
          ...CgbTestimonialBlock
          ...CgbTestimonialSliderBlock
          ...CgbTimelineBlock
          ...CgbTimelineInnerBlock
          ...CgbThickIntroBlock
          ...CgbTwoColInnerBlock
          ...CgbTwoColWrapperBlock
          ...CgbVacancySliderBlock
          ...CgbVideoBannerMessageBlock
          ...CgbVideoEmbedBlock
          ...CgbVideoTestimonialBlock
          ...AcfAirshipEmbedBlock
          ...AcfTimedContainerBlock
          ...AcfFaqsBlock
          ...AcfBrewGalleryBlock
          ...AcfFullWidthImageBlock
        }
      }
    }
  }
`;

export const PreviewBlocks = `
  ${CoreParagraphBlockPreview}
  ${CoreHeadingBlockPreview}
  ${CoreColumnBlockPreview}
  ${CoreColumnsBlockPreview}
  ${CoreImageBlockPreview}
  ${CoreButtonsBlockPreview}
  ${CoreButtonBlockPreview}
  ${CoreGalleryBlockPreview}
  ${CoreGalleryBlockPreview}
  ${CoreQuoteBlockPreview}
  ${CoreListBlockPreview}
  ${CoreListItemBlockPreview}
  ${CoreTableBlockPreview}
  ${CoreFileBlockPreview}
  ${CoreSpacerBlockPreview}
  ${GravityformsFormBlockPreview}

  ${CgbAvatarBreakdownBlockPreview}
  ${CgbBannerCalloutCtaBlockPreview}
  ${CgbBannerMessageBlockPreview}
  ${CgbBeerListingBlockPreview}
  ${CgbBlogPostBannerBlockPreview}
  ${CgbBlogPostWrapBlockPreview}
  ${CgbBreakoutGalleryIntroBlockPreview}
  ${CgbCalloutCtaBlockPreview}
  ${CgbCardBlockPreview}
  ${CgbCardRowBlockPreview}
  ${CgbContactCalloutBlockPreview}
  ${CgbContactFormBrandBlockPreview}
  ${CgbFeaturedIconsBlockPreview}
  ${CgbFeaturedIconsInnerBlockPreview}
  ${CgbFeaturedProductsBlockPreview}
  ${CgbFeaturedTextBlockPreview}
  ${CgbFindPubBlockPreview}
  ${CgbFormCareersBlockPreview}
  ${CgbFormSignUpBlockPreview}
  ${CgbFormVacancySignUpBlockPreview}
  ${CgbGoodToGoBlockPreview}
  ${CgbImageBreakdownBlockPreview}
  ${CgbImageBreakdownInnerBlockPreview}
  ${CgbImageContentRowsBlockPreview}
  ${CgbImageContentRowsInnerBlockPreview}
  ${CgbImageLinkSliderBlockPreview}
  ${CgbImageLinkSliderInnerBlockPreview}
  ${CgbLatestPostsBlockPreview}
  ${CgbPubListingBlockPreview}
  ${CgbSeparatorBlockPreview}
  ${CgbSocialSharingBlockPreview}
  ${CgbStatColumnsBlockPreview}
  ${CgbTabContentBlockPreview}
  ${CgbTabContentInnerBlockPreview}
  ${CgbTestimonialBlockPreview}
  ${CgbTestimonialSliderBlockPreview}
  ${CgbTimelineBlockPreview}
  ${CgbTimelineInnerBlockPreview}
  ${CgbThickIntroBlockPreview}
  ${CgbTwoColInnerBlockPreview}
  ${CgbTwoColWrapperBlockPreview}
  ${CgbVacancySliderBlockPreview}
  ${CgbVideoBannerMessageBlockPreview}
  ${CgbVideoEmbedBlockPreview}
  ${CgbVideoTestimonialBlockPreview}
  ${AcfAirshipEmbedBlockPreview}
  ${AcfBrewGalleryBlockPreview}
  ${AcfFaqsBlockPreview}
  ${AcfFullWidthImageBlockPreview}
  ${AcfPubListingBlockPreview}
  ${AcfTimedContainerBlockPreview}
  `;
